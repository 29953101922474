import "bootstrap-sass";

require("@SmartAdminBundle/scss/npm_bootstrap.scss");
require("@SmartAdminBundle/scss/font-awesome.scss");
require("@SmartAdminBundle/scss/smartadmin-production-plugins.scss");
require("@SmartAdminBundle/scss/smartadmin-production.scss");
require("@SmartAdminBundle/scss/smartadmin-skins.scss");
require("@SmartAdminBundle/scss/meeva_smartadmin.scss");

(function (window, $) {
  $(() => {
    $(document).on("click", "fieldset.collapsible > legend", function () {
      const $fieldset = $(this).parent();

      $fieldset.find("> *:not(legend)").toggle();
      $fieldset.toggleClass("open");
    });
  });

  /*
   * CUSTOM MENU PLUGIN
   */
  $.fn.extend({
    // pass the options variable to the function
    jarvismenu(options) {
      const defaults = {
        accordion: "true",
        speed: 200,
        closedSign: "[+]",
        openedSign: "[-]"
      };

      // Extend our default options with those provided.
      const opts = $.extend(defaults, options);
      // Assign current element to variable, in this case is UL element
      const $this = $(this);

      // add a mark [+] to a multilevel menu
      $this.find("li").each(function () {
        if ($(this).find("ul").length !== 0) {
          // add the multilevel sign next to the link
          $(this).find("a:first").append(`<b class='collapse-sign'>${opts.closedSign}</b>`);

          // avoid jumping to the top of the page when the href is an #
          if ($(this).find("a:first").attr("href") === "#") {
            $(this).find("a:first").click(() => false);
          }
        }
      });

      // open active level
      let activeSubMenu = $this.find("li.active");
      if (!activeSubMenu.length && sessionStorage.getItem("lastActiveMenuItem")) {
        activeSubMenu = $this.find(`a[href="${sessionStorage.getItem("lastActiveMenuItem")}"]`).closest("li");
      } else if (activeSubMenu.length) {
        const $a = activeSubMenu.last().children("a[href]");
        if ($a.attr("href") && $a.attr("href") !== "#") {
          sessionStorage.setItem("lastActiveMenuItem", $a.attr("href"));
        } else {
          sessionStorage.removeItem("lastActiveMenuItem");
        }
      }

      // Drop Down Menu can sometimes be bugged and stayed open even on page refresh
      if (!activeSubMenu.parent().hasClass("dropdown-menu")) {
        activeSubMenu.each(function () {
          $(this).parents("ul").slideDown(opts.speed);
          $(this).parents("ul").parent("li").find("b:first")
            .html(opts.openedSign);
          $(this).parents("ul").parent("li").addClass("open");
        });
      }

      $this.find("li.default-open").each(function () {
        $(this).children("ul").slideDown(opts.speed);
        $(this).find("b:first").html(opts.openedSign);
        $(this).addClass("open");
      });

      $this.find("li a").click(function () {
        if ($(this).parent().find("ul").length !== 0) {
          // Menü Gruppe (auf/zu klappen)

          if (opts.accordion) {
            // Do nothing when the list is open
            if (!$(this).parent().find("ul").is(":visible")) {
              const parents = $(this).parent().parents("ul");
              const visible = $this.find("ul:visible");

              visible.each(function (visibleIndex) {
                let close = true;
                parents.each((parentIndex) => {
                  if (parents[parentIndex] === visible[visibleIndex]) {
                    close = false;
                    return false;
                  }

                  return true;
                });

                if (close) {
                  if ($(this).parent().find("ul") !== visible[visibleIndex]) {
                    $(visible[visibleIndex]).slideUp(opts.speed, function () {
                      $(this).parent("li").find("b:first").html(opts.closedSign);
                      $(this).parent("li").removeClass("open");
                    });
                  }
                }
              });
            }
          }// end if
          if ($(this).parent().find("ul:first").is(":visible") && !$(this).parent().find("ul:first").hasClass("active")) {
            $(this).parent().find("ul:first").slideUp(opts.speed, function () {
              $(this).parent("li").removeClass("open");
              $(this).parent("li").find("b:first").delay(opts.speed)
                .html(opts.closedSign);
            });
          } else {
            $(this).parent().find("ul:first").slideDown(opts.speed, function () {
              /* disabled due to CPU clocking on phones */
              /* $(this).effect("highlight", {color : '#616161'}, 500); */
              $(this).parent("li").addClass("open");
              $(this).parent("li").find("b:first").delay(opts.speed)
                .html(opts.openedSign);
            });
          }
        } else {
          // Normaler Menü-Eintrag

          // eslint-disable-next-line no-lonely-if
          if ($(this).attr("href") && $(this).attr("href") !== "#") {
            sessionStorage.setItem("lastActiveMenuItem", $(this).attr("href"));
          } else {
            sessionStorage.removeItem("lastActiveMenuItem");
          }
        }
      });
    }
  });
}(window, jQuery));
